import React from 'react';
import { graphql } from 'gatsby';
// Todo: Re-add when implementing product images
// import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import { Col, Container, Row } from 'react-bootstrap';

import * as Globals from '../../../globals';
import Layout from '../../components/layout/layout';
import Seo from '../../components/seo/seo';
import ChatBanner from '../../components/chatBanner/chatBanner';
import Styles from './case-study.styles';

const SproutPage = ({ data }) => {
  let backgroundFluidImageStack = [
    data.background.childImageSharp.fluid,
    Globals.backgroundOverlay,
  ].reverse();

  return (
    <Layout>
      <Seo title="WAX Insurance | Delivering luxury on a deadline" />
      <Styles>
        <BackgroundImage Tag="header" fluid={backgroundFluidImageStack}>
          <Container>
            <Row>
              <Col
                xs={{ span: 10, offset: 1 }}
                md={{ span: 8, offset: 0 }}
                xl={{ span: 6, offset: 0 }}
              >
                <h1>
                  <small>WAX Insurance</small>
                  <br />
                  Delivering luxury on a deadline
                </h1>
              </Col>
            </Row>
          </Container>
        </BackgroundImage>

        <Container className="mt-4 mt-md-5 mb-4">
          <Row className="justify-content-center">
            <Col
              xs={{ span: 11, order: 2 }}
              md={{ span: 8, order: 1 }}
              lg={{ span: 7, offset: 1 }}
            >
              <section>
                <p className="lead mb-4 mb-md-5">
                  WAX claimed first-mover status with a product one leading VC
                  described as being in the top 0.1% of startup launch releases.
                  This quality translated into a launch that exceeded
                  expectations across every key metric, and positioned WAX for
                  significant new funding &amp; growth opportunities.
                </p>
                <h3>The Challenge</h3>
                <p>
                  An insuretech startup with a passion for collectibles, WAX
                  needed to beat more established competition to market in a
                  race that would establish the industry leader. The team turned
                  to Generativ to deliver a product fast enough to secure this
                  foothold, and strong enough to keep it.
                </p>
                <h3>Our Approach</h3>
                <p>
                  Partnering with WAX's formidable team of investors, domain
                  experts, and marketing talent, we stepped into a product
                  leadership role defined by our laser focus on delivering the
                  best MVP for launch. That meant building ultra-high fidelity
                  prototypes the team could collaboratively explore and iterate
                  on in parallel to operational bootstrapping and backend
                  development.
                </p>
                <p>
                  Once the product felt right, we were able to use the latest
                  &amp; greatest in cross-platform mobile and our own
                  proprietary tooling, coupled with a stubborn refusal to miss
                  deadlines, to deliver an experience that would satisfy even
                  WAX's most demanding users.
                </p>
              </section>

              {/* Todo: Re-add after populating product images */}
              {/*<hr className="my-5 ml-0" />*/}
            </Col>

            <Col
              xs={{ span: 11, order: 1 }}
              md={{ span: 4, order: 2 }}
              lg={{ span: 3 }}
            >
              <aside className="mb-4 mb-md-5">
                <h4>Insuretech</h4>

                <hr className="hr-small my-4 ml-0" />

                <ul>
                  <li>CTO</li>
                  <li>Development</li>
                  <li>Operations</li>
                  <li>Advisory</li>
                </ul>

                <hr className="hr-small my-4 ml-0" />

                <a
                  href="https://wax.insure"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  wax.insure
                </a>
              </aside>
            </Col>
          </Row>

          {/* Todo: Add product images */}
          {/*<Row className="justify-content-center">
            <Col xs={{ span: 11 }} md={{ span: 12 }} lg={{ span: 10 }}>
              <section className="product-images">
                <h2 className="mb-3">
                  Product Headline. Lorem ipsum dolor sit amet.
                </h2>
                <Row>
                  <Col>
                    <Img fluid={data.product.childImageSharp.fluid} />
                  </Col>
                  <Col>
                    <Img fluid={data.product.childImageSharp.fluid} />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Img fluid={data.product.childImageSharp.fluid} />
                  </Col>
                  <Col>
                    <Img fluid={data.product.childImageSharp.fluid} />
                  </Col>
                </Row>
              </section>
            </Col>
          </Row>*/}
        </Container>
      </Styles>
      <ChatBanner />
    </Layout>
  );
};

export const query = graphql`
  query {
    background: file(relativePath: { eq: "backgrounds/bg-work-wax.jpg" }) {
      ...imageFluidExtraLarge
    }
    product: file(relativePath: { eq: "placeholders/3x2.png" }) {
      ...imageFluidMedium
    }
  }
`;

export default SproutPage;
